import React from "react";
import "./services.css";
import motor from "../../assets/services-motor.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faHeartbeat,
  faCar,
  faLifeRing,
  faBriefcase,
} from "@fortawesome/free-solid-svg-icons";
import about2 from "../../assets/about2.svg";

const Services = () => {
  return (
    <section className="services-main">
      <header className="services-header">
        <h1 className="services-title">
          Our Services{" "}
          <img src={about2} alt="About our services icon" className="about2" />
        </h1>
        <h2 className="services-subline">
          What We Can Offer to <span className="about-orange">Our Clients</span>
        </h2>
        <nav className="circle-buttons" aria-label="Service Categories">
          <button className="circle-button" aria-label="Home Insurance">
            <FontAwesomeIcon icon={faHome} />
          </button>
          <button className="circle-button" aria-label="Health Insurance">
            <FontAwesomeIcon icon={faHeartbeat} />
          </button>
          <button className="circle-button active" aria-label="Motor Insurance">
            <FontAwesomeIcon icon={faCar} />
          </button>
          <button className="circle-button" aria-label="Life Insurance">
            <FontAwesomeIcon icon={faLifeRing} />
          </button>
          <button className="circle-button" aria-label="Business Insurance">
            <FontAwesomeIcon icon={faBriefcase} />
          </button>
        </nav>
      </header>

      <article className="services-content">
        <figure className="left-image">
          <img
            src={motor}
            alt="Motor insurance coverage"
            className="motorImg"
          />
        </figure>
        <section className="right-content">
          <h2>Motor Insurance</h2>
          <p>
            <strong> Motor Insurance</strong> is a policy that protects your
            vehicle from financial risks due to accidents, theft, or other
            damages. It provides coverage for both your vehicle and third-party
            liabilities.
          </p>
          <button>Request Services</button>
        </section>
      </article>
    </section>
  );
};

export default Services;
