import React from "react";
import "./hero.css";

const Hero = () => {
  return (
    <div className="hero">
      <div className="hero-overlay"></div>
      <div className="hero-content">
        {/* 1 wrapper */}
        <div className="wrapper1">
          <div className="detail" id="detail-id">
            <span className="number">8+</span>
            <span className="description">Years in Business</span>
          </div>
          <div className="detail" id="detail-id">
            <span className="number">1000+</span>
            <span className="description">Dedicated financial Partners</span>
          </div>
        </div>
        {/* 2nd wrapper */}
        <div className="wrapper2">
          <div className="detail" id="detail-id">
            <span className="number">200,000+</span>
            <span className="description">Satisfied Clients</span>
          </div>
          <div className="detail" id="detail-id">
            <span className="number">22+</span>
            <span className="description">Insurance Companies</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
