import test1 from '../../assets/test1.svg'; // Change the file names accordingly
import test2 from '../../assets/test2.svg';
import test3 from '../../assets/test3.svg';
import test4 from '../../assets/test1.jpg';
import test5 from '../../assets/test2.svg';
export const reviews = [
    {
      id: 1,
      image: test1,
      name: 'Ankit Soni',
      text: 'Thanking you very much for your support for getting our policy quickly. I would appreciate your work.',
    },
    {
      id: 2,
      image:test2,
      name: 'Ajay Sain',
      text: 'Be transparent about your policies, coverage, and pricing. Customers appreciate clarity and honesty when it comes to their insurance needs.',
    },
    {
      id: 3,
      image:
       test3,
      name: 'Aakash',
      text: 'You are doing a great job. Proud to be a customer of SafeKaro.',
    },
    {
      id: 4,
      image:
        test4,
      name: 'Rakesh Meena',
      text: 'Be transparent about your policies, coverage, and pricing. Customers appreciate clarity and honesty when it comes to their insurance needs.',
    },
    {
      id: 5,
      image:
       test5,
      name: 'Rohit Kumar',
      text: "The product not only met but exceeded my expectations. It's clear that the team is dedicated to delivering high-quality work. I'm a satisfied customer.",
    },
  ];