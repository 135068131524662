import React from "react";
import "./Companies.css";
import logo1 from "../../assets/digit-insurance.svg";
import logo2 from "../../assets/hdfc ergo.png";
import logo3 from "../../assets/kotak life.svg";
import logo4 from "../../assets/pngwing.com (7).png";
import logo5 from "../../assets/sbi-genral.png";
import logo6 from "../../assets/-Star_Health_.png";
import logo7 from "../../assets/Universal sompo.png";
//import logo9 from '../../assets/Bajaj_Allianz_Insurance.svg';
import logo10 from "../../assets/CARE-HI-LOGO-01-PNG.png";
import logo11 from "../../assets/chola ms.png";
import logo12 from "../../assets/Future generali.png";
import logo13 from "../../assets/icici.webp";
import logo14 from "../../assets/iffco.svg";
import logo15 from "../../assets/magma.jpeg";
import logo16 from "../../assets/ManipalCigna-min.png";
import logo17 from "../../assets/National logo.svg";
import logo18 from "../../assets/new india.png";
import logo19 from "../../assets/niva bupa.png";
import logo20 from "../../assets/rahega.webp";
import logo21 from "../../assets/royal sundaram.svg";
import logo22 from "../../assets/sbi-genral.png";
import {useState} from "react";

const Companies =()=>{
  const[companyLogos, setCompanyLogos]= useState ([
    {name:'Digit',path:logo1},
    {name:'HDFC',path:logo2},
    {name:'Kotak',path:logo3},
    {name:'LIC',path:logo4},
    {name:'SBI',path:logo5},
    {name:'STAR',path:logo6},
    {name:'Universal Sompo',path:logo7},
    {name:'Care Health',path:logo10},
    {name:'Chola MS',path:logo11},
    {name:'Future Generali',path:logo12},
    {name:'ICICI',path:logo13},
    {name:'IFFCO',path:logo14},
    {name:'MAGMA',path:logo15},
    {name:'ManipalCigna',path:logo16},
    {name:'National Insurance',path:logo17},
    {name:'New India',path:logo18},
    {name:'Niva Bupa',path:logo19},
    {name:'Raheja QBE',path:logo20},
    {name:'Royal Sundaram',path:logo21},
    {name:'SBI',path:logo22},
  ])
  return (
    <div className="companies-main">
      <div className="companies-wrapper">
        {[...companyLogos, ...companyLogos].map((logo, index) => (
          <div
          data-message={logo.name}
            key={index}
            className={`company-logo ${index % 2 === 0 ? "top" : "bottom"}`}
          >
            <img src={logo.path} alt={`Company ${index.name}`} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Companies;
