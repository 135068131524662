
export function saveToSessionStorage(key, data) {
    if (data) {
        const jsonData = JSON.stringify(data);
        sessionStorage.setItem(key, jsonData);
    } else {
        console.error('Data is null or undefined.');
    }
}

// Function to get data from session storage
export function getFromSessionStorage(key) {
    const jsonData = sessionStorage.getItem(key);
    if (jsonData) {
        return JSON.parse(jsonData);
    } else {
        console.warn(`No data found for key: ${key}`);
        return null; 
    }
}




