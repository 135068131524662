import React from "react";
import logo from "../../assets/logo2.svg";
import "./footer.css";
import location from "../../assets/location.png";
import email from "../../assets/email.png";
import phone from "../../assets/phone.png";
import linkedin from "../../assets/linkedin.png";
import twitter from "../../assets/twitter.png";
import instagram from "../../assets/instagram.png";
import facebook from "../../assets/facebook.png";

const Footer = () => {
  return (
    <footer id="Footer">
      <div className="row">
        <div className="col">
          <img src={logo} alt="logo" className="footer-logo" />
          <p className="para">
            We are here to help you with all your insurance needs. Reach out us
            today to learn more about our service and how we can help protect
            what matter most to you.
          </p>
          <div className="flex my-2 gap-6  ">
            <a
              className="cursor-pointer"
              href="https://www.facebook.com/safekaroindia/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={facebook} alt="facebook" />
            </a>
            <a href="http://" target="_blank" rel="noopener noreferrer">
              <img src={twitter} alt="twitter" />
            </a>
            <a
              className="cursor-pointer"
              href="https://in.linkedin.com/company/safe-karo"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={linkedin} alt="linkedin" />
            </a>
            <a
              className="cursor-pointer"
              href="https://www.instagram.com/safekaro/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={instagram} alt="instagram" />
            </a>
          </div>
        </div>
        <div className="w-full md:w-1/4 mb-6 ml-2 md:ml-0">
          <h3 className="text-[#E59411] md:w-1/2 relative mb-6 w-full">
            <span className="flex ">Service</span>
            <span className="underline  h-1 bg-gray-600 rounded mt-1"></span>
          </h3>
          <ul className="list-none space-y-2">
            <li>Health Insurance</li>
            <li>Car Insurance</li>
            <li>Home Insurance</li>
            <li>Life Insurance</li>
            <li>Business Insurance</li>
          </ul>
        </div>
        <div className="w-full md:w-1/4 mb-6 ml-2 md:ml-0">
        <h3 className="text-[#E59411] md:w-1/2 relative mb-6 w-full">
            Quick Links
            <div className="underline  h-1 bg-gray-600 rounded mt-1"></div>
          </h3>
          <ul className="list-none space-y-2">
            <li>
              <a href="#about" className="text-white">
                About Us
              </a>
            </li>
            <li>
              <a href="#contact" className="text-white">
                Contact Us
              </a>
            </li>
            <li>
              <a href="#help" className="text-white">
                Help
              </a>
            </li>
            <li>
              <a href="#service" className="text-white">
                Service Request
              </a>
            </li>
            <li>
              <a href="#claims" className="text-white">
                Claims
              </a>
            </li>
          </ul>
        </div>
        <div className="w-full md:w-1/4 mb-6 ml-2 md:ml-0">
        <h3 className="text-[#E59411] md:w-1/2 relative mb-6 w-full">
            SafeKaro Head Office
            <div className="underline  h-1 bg-gray-600 rounded mt-1"></div>
          </h3>
          <div>
            <ul className="list-none space-y-4">
              <li className="flex items-center gap-3">
                <img src={location} alt="location" className="w-5 h-5" />
                <p>
                  505, 5th Floor, Royal World, Sansar Chandra Road, Jaipur -
                  302001
                </p>
              </li>
              <li className="flex items-center gap-3">
                <img src={email} alt="email" className="w-5 h-5" />
                <p>support@safekaro.com</p>
              </li>
              <li className="flex items-center gap-3">
                <img src={phone} alt="phone" className="w-5 h-5" />
                <p>0141 - 4806690</p>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <br />
      <hr />
      <p className="copyright">
        {" "}
        ©️ 2024 SafeKaro. All rights reserved. Design by{" "}
        <a id="blueSparingLink" href="https://bluesparing.com" target="blank">
          Blue Sparing
        </a>{" "}
        with<span className="blinking-heart">❤️</span>{" "}
      </p>
    </footer>
  );
};

export default Footer;
