import React from "react";
import Home from "../Home/Home";
import About from "../About/About";
import Services from "../Services/Services";
import Blog from "../Blog/Blog";
import Hero from "../Hero/Hero";
import Testimonials from "../Testimonial/Testimonial";
import Companies from "../Companies/Companies";
import Footer from "../Footer/Footer";
import WhySafeKaro from "../WhySafeKaro/WhySafeKaro";
import Newsletter from "../NewsLetter/NewsLetter";

const Website = () => {
  return (
    <>
      <div>
        <section id="home">
          <Home />
        </section>
        <section id="about">
          <About />
        </section>
        <section id="services">
          <Services />
        </section>
        <section id="blog">
          <Blog />
        </section>
        <section id="hero">
          <Hero />
        </section>
        <section id="news">
          <Newsletter />
        </section>

        <section id="why-safe-karo">
          <WhySafeKaro />
        </section>
        <section id="testimonials">
          <Testimonials />
        </section>
        <section id="companies">
          <Companies />
        </section>
        <section id="footer">
          <Footer />
        </section>
      </div>
    </>
  );
};

export default Website;
