import React, { Suspense } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Navbar from "./components/Navbar/Navbar";
import Website from "./components/Website/Website";

// Lazy loaded components
const NewsDetails = React.lazy(() =>
  import("./components/NewsLetter/NewsDetails")
);
const BlogDetails = React.lazy(() => import("./components/Blog/BlogDetails"));
const NotFound = React.lazy(() => import("./components/Notfound/NotFound"));

const keywords = {
  general: [
    "cheapest insurance in Jaipur",
    "affordable insurance in Jaipur",
    "best insurance companies in Jaipur",
    "insurance quotes in Jaipur",
    "insurance plans in Jaipur",
    "insurance providers in Jaipur",
    "top insurance agencies in Jaipur",
  ],
  motor: [
    "motor insurance in Jaipur",
    "cheapest motor insurance in Jaipur",
    "affordable motor insurance in Jaipur",
    "best motor insurance in Jaipur",
    "third-party motor insurance Jaipur",
    "comprehensive motor insurance Jaipur",
    "motor insurance quotes in Jaipur",
    "motor insurance renewal in Jaipur",
  ],
  health: [
    "health insurance in Jaipur",
    "cheapest health insurance in Jaipur",
    "family health insurance in Jaipur",
    "best health insurance plans in Jaipur",
    "health insurance quotes in Jaipur",
  ],
  home: [
    "home insurance in Jaipur",
    "affordable home insurance in Jaipur",
    "best home insurance in Jaipur",
    "home insurance quotes in Jaipur",
  ],
  vehicle: [
    "two-wheeler insurance in Jaipur",
    "car insurance in Jaipur",
    "cheapest car insurance in Jaipur",
    "comprehensive car insurance in Jaipur",
  ],
  life: [
    "life insurance in Jaipur",
    "term life insurance in Jaipur",
    "cheapest life insurance in Jaipur",
    "best life insurance plans in Jaipur",
  ],
  other: [
    "insurance comparison Jaipur",
    "online insurance in Jaipur",
    "insurance agents in Jaipur",
    "reviews of insurance companies in Jaipur",
  ],
};

const App = () => {
  return (
    <Router>
      <Navbar />
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route path="/" element={<Website />} />
          <Route path="blogs/:id" element={<BlogDetails />} />
          <Route path="news/:id" element={<NewsDetails />} />
          <Route path="*" element={<NotFound />} />
          {Object.entries(keywords).flatMap(([category, keywordArray]) =>
            keywordArray.map((keyword) => {
              const formattedKeyword = keyword.replace(/ /g, "-").toLowerCase();
              return (
                <Route
                  key={formattedKeyword}
                  path={`/${formattedKeyword}`}
                  element={<Website />}
                />
              );
            })
          )}
        </Routes>
      </Suspense>
    </Router>
  );
};

export default App;
