import React from "react";
import img from "../../assets/about.svg";
import about2 from "../../assets/about2.svg";

const About = () => {
  return (
    <section
      className="overflow-hidden w-full  h-auto bg-gray-200"
      id="about-id"
      aria-labelledby="about-heading"
    >
      <div className="flex flex-col mt-10 py-10 mx-auto max-w-6xl px-4">
        <header className="flex flex-col my-5 justify-center items-center">
          <h1
            className="text-lg font-bold flex items-center relative"
            id="about-heading"
          >
            About Us
            <img
              src={about2}
              alt="Illustration of our services"
              className="ml-2 w-8 h-8" // Fixed size for icon
            />
          </h1>
          <h2 className="text-3xl font-bold text-center">
            Welcome to <span className="text-blue-600">Safe</span>
            <span className="text-orange-400">Karo</span>
          </h2>
        </header>
        <div className="flex flex-col md:flex-row justify-evenly items-center gap-8 w-full">
          <div className="flex justify-center mb-10 md:mb-0 w-full">
            <img
              className="h-auto w-full max-w-xs md:max-w-md md:h-[400px] object-cover"
              src={img}
              alt="SafeKaro illustration showing our services"
            />
          </div>
          <div className="max-w-md">
            <p className="text-base font-medium leading-relaxed mt-4 text-justify">
              At <strong className="font-bold">SAFEKARO</strong>, we are
              dedicated to providing comprehensive and reliable insurance
              solutions, including <strong>car insurance</strong> and{" "}
              <strong>travel insurance</strong>, tailored to meet the diverse
              needs of our clients. With 8 years of experience in the{" "}
              <strong>insurance industry</strong>, we have built a reputation
              for excellence and commitment to our customers. Our mission is to
              offer peace of mind to our clients by protecting what matters most
              to them, whether through <strong>car insurance quotes</strong> or
              personalized <strong>insurance quotes</strong> for various
              coverage options. We strive to provide exceptional service,
              innovative products like <strong>Admiral car insurance</strong>,
              and a seamless claims process facilitated by our experienced{" "}
              <strong>insurance agents</strong>. Our{" "}
              <strong>insurance adjusters</strong> are dedicated to ensuring our
              clients feel secure and supported throughout their experience with
              us.
            </p>
            <div className="mt-4">
              <button className="bg-blue-600 text-white px-4 py-2 rounded shadow-md hover:bg-blue-700 transition">
                Read More
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
