import React from "react";
import homeRightImg from "../../assets/home.svg";
import Hicon1 from "../../assets/Hicon1.svg";
import Hicon2 from "../../assets/Hicon2.svg";
import Hicon3 from "../../assets/Hicon3.svg";
import Hdot1 from "../../assets/Hdot1.svg";
import Hdot2 from "../../assets/Hdot2.svg";
import phone from "../../assets/phone.png";
import arrow from "../../assets/arrow1.png";

const Home = () => {
  return (
    <main
      className="bg-gradient-to-r from-[#002C40] to-[#2C2C2C] w-screen h-screen flex flex-col md:flex-row justify-center items-center p-10 md:p-5"
      role="main"
    >
      <div className="flex flex-col items-start mt-12 md:mt-24 md:ml-16">
        <h1 className="font-extrabold text-2xl mb-10  md:text-4xl text-white text-center md:text-left">
          Get <span className="text-[#027AAE]">Insurance </span> in 2{" "}
          <span className="text-[#E59411]">Minutes</span>
        </h1>
        <div className="flex flex-col md:flex-row items-center gap-7 mt-6">
          <div className="bg-[#f7f1f1] w-full md:w-36 h-24 flex flex-col justify-center items-center rounded-lg relative p-5">
            <img
              src={Hicon3}
              alt="Fill details icon"
              className="absolute top-[-20px] left-1/4 w-8 h-6"
            />
            <h3 className="text-center font-Poppins text-lg">Fill details</h3>
          </div>
          <span className="hidden md:block">
            <img
              src={arrow}
              alt="arrow pointing to next step"
              className="w-7 h-7"
            />
          </span>

          <div className="bg-[#f7f1f1] w-full md:w-36 h-24 flex flex-col justify-center items-center rounded-lg relative p-5">
            <img
              src={Hicon2}
              alt="Compare quotes and insurers icon"
              className="absolute top-[-20px] left-1/4 w-8 h-6"
            />
            <h3 className="text-center font-Poppins text-lg">
              Compare Quote & Insurer
            </h3>
          </div>
          <span className="hidden md:block">
            <img
              src={arrow}
              alt="arrow pointing to next step"
              className="w-7 h-7"
            />
          </span>

          <div className="bg-[#f7f1f1] w-full md:w-36 h-24 flex flex-col justify-center items-center rounded-lg relative p-5">
            <img
              src={Hicon1}
              alt="Make payment and relax icon"
              className="absolute top-[-20px] left-1/4 w-8 h-6"
            />
            <h3 className="text-center font-Poppins text-lg">
              Make Payment & Relax
            </h3>
          </div>
        </div>
        <div className="flex flex-col  md:flex-row mt-6 items-center md:items-start">
          <button className="m-2 p-3 border border-[#027AAE] bg-[#027AAE] text-white rounded-lg w-full md:w-auto">
            Get a Quote
          </button>
          <a
            href="#Footer"
            className="flex items-center justify-center m-2 p-3 border-2 border-[#E59411] bg-transparent text-white rounded-lg cursor-pointer gap-4 w-full md:w-auto"
            aria-label="Contact expert"
          >
            <img src={phone} alt="Contact expert icon" />
            Contact Expert
          </a>
        </div>
      </div>
      <div className=" hidden md:flex justify-center items-center relative mt-8 md:mt-0">
        <img
          src={Hdot1}
          alt="Background decoration"
          className="absolute right-0"
        />
        <img
          src={homeRightImg}
          alt="Main visual representing insurance"
          className="w-4/5 h-auto md:w-4/5 md:h-4/5 mt-6 md:mt-24 md:ml-32"
        />
        <img
          src={Hdot2}
          alt="Background decoration"
          className="absolute bottom-0 right-0"
        />
      </div>
    </main>
  );
};

export default Home;
